/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect, useState } from 'react'
import { CustomModal } from '@cd/sdds-common-components-react'
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../redux/store';
import "./historicalDataVariablesDialog.scss"
import { RBACWrapper } from 'react-simple-rbac';
import { getColumnsConfig } from '../../../helpers/communicationHistoryService';

type HistoricalDataType = {
  MessageKeyList: HistoricalDataResponseType[]
  onColumnsSelectionChange?: (selectedColumns: CommunicationColumnConfigType[], unselectedColumns:CommunicationColumnConfigType[]) => void
  onRemovedColumn?: string
  onResetDefaultColumn: () => void
}

const HistoricalDataVariablesDialog = ({ MessageKeyList, onColumnsSelectionChange, onRemovedColumn, onResetDefaultColumn }: HistoricalDataType) => {

  const { t } = useTranslation();
  const [addColumnTableOpen, setAddColumnTableOpen] = useState(false);
  const { selectedColumns } = useAppSelector(state => state.historicalDataStore);
  const convertType = (v:CommunicationColumnConfigType[])=>{
   return selectedColumns.filter( item => !getColumnsConfig(t).columns.find(column => item.columnid === column.columnid ) ).map((item) => {
      return {
        value: item.title,
        dataTitle: item.dataTitle,      
        type: item.type ? item.type : '',
        checked: true      
      }
    });
  }
  const [selectedList, setSelectedList] = useState<HistoricalDataResponseType[]>(convertType(selectedColumns)); //contains current state of selections in the selection dialog
  const [savedList, setSavedList] = useState<HistoricalDataResponseType[]>(convertType(selectedColumns)); //contains all the saved selections at all times.
  const [filteredList, setFilteredList] = useState<HistoricalDataResponseType[]>(MessageKeyList);
  const [unselectedList, setUnSelectedList] = useState<HistoricalDataResponseType[]>([]);
 

  useEffect(() => {
    setFilteredList(MessageKeyList);
  }, [MessageKeyList])

  useEffect(() => {
    if(onRemovedColumn){
      setSavedList(savedList.filter(p => p.value.toLowerCase() !== onRemovedColumn?.toLowerCase()));
      setSelectedList(selectedList.filter(p => p.value.toLowerCase() !== onRemovedColumn?.toLowerCase()));
      updatingFilteredList(onRemovedColumn, false);
    }
  }, [onRemovedColumn])

  const handleSearchItem = (e: any) => {
    let filteredList = MessageKeyList.filter(item => item.value.toLowerCase().includes(e.target.value.toLowerCase()));
    setFilteredList(filteredList)
    if(selectedList.length > 0){
      selectedList.forEach((item: HistoricalDataResponseType) => {
        updatingFilteredList(item.value, true);
      })
    }
  };
  
  const handleCheckboxChange = (event: any, item: HistoricalDataResponseType) => {
    const objItem = { ...item };
    objItem.checked = event.target?.checked;

    if (!event.target?.checked) {
      setUnSelectedList(unselectedList.length > 0 ? unselectedList.concat([item]) : [item]);

      if (selectedList.length > 0) {
        setSelectedList(selectedList.filter(p => p.value.toLowerCase() !== item.value.toLowerCase()));
        updatingFilteredList(item.value, event.target?.checked);
      }
    } else {
      setSelectedList([objItem, ...selectedList]);
      updatingFilteredList(item.value, event.target?.checked);
    }
  }

  const updatingFilteredList = (itemValue: string, checkedStatus: boolean) => {
    setFilteredList((prevList: HistoricalDataResponseType[]) => {
      return prevList.map((prevItem: HistoricalDataResponseType) => {
        if (prevItem.value === itemValue) {
          return { ...prevItem, checked: checkedStatus }
        }
        return prevItem;
      })
    })
  }

  const FilterdListUnchecking = () => {
    setFilteredList((prevList: HistoricalDataResponseType[]) => {
      return prevList.map((newItem: HistoricalDataResponseType) => {
        return { ...newItem, checked: false }
      })
    })
  }

  const clearAllUnsavedSelections = () => {
    setSelectedList(savedList);
    FilterdListUnchecking();
  }

  const handleAddColumn = () => {
    setAddColumnTableOpen(true)
  }

  const handleResetDefaultData = () => {
    setSelectedList([]);
    setSavedList([]);
    setFilteredList(MessageKeyList);
    onResetDefaultColumn();
  }

  const saveAddColumn = () => {
    
    setSavedList(selectedList.sort((a : any, b: any) => a.value > b.value ? 1 : -1));
    const selectedColumnsToSave: CommunicationColumnConfigType[] = selectedList.map((item) => {
      return {
        columnid: item.value,
        title: item.value,
        sortable: false,
        type: item.type ? item.type : '',
        convert: item.convert ? item.convert : false,
        removeable: true,
        dataTitle: item.dataTitle
      }
    });
     const unselectedColumnsToSave: CommunicationColumnConfigType[] = unselectedList.map((item) => {
      return {
        columnid: item.value,
        title: item.value,
        sortable: false,
        type: item.type ? item.type : '',
        convert: item.convert ? item.convert : false,
        removeable: true,
        dataTitle: item.dataTitle
      }
    }); 
    onColumnsSelectionChange?.(selectedColumnsToSave, unselectedColumnsToSave);
    setAddColumnTableOpen(false);
  }

  const handleCancelButton = () => {
    setSelectedList(selectedList.filter(item => savedList.some(savedItem => savedItem.value  === item.value)));
    setFilteredList(MessageKeyList);
    setAddColumnTableOpen(false);
  }

  const renderLists = () => {
    return (
      <>
        {savedList.length > 0 && savedList.map((item, index) => (
          <>
            <li
              title={item.value}
              key={index}
              onClick={(e) => handleCheckboxChange(e, item)}
              onKeyDown={(e) => handleCheckboxChange(e, item)}
            >
              <label>
                <input className="sdds-form-input" type="checkbox" defaultChecked={true} onChange={(e) => handleCheckboxChange(e, item)} />
                {item.value} {item.type ? ` (${item.type})`: '' }
              </label>
            </li>
          </>
        ))}
        {filteredList.map((item, index) => {
          const isItemSaved = savedList.some((p) => p.value === item.value);
          if (isItemSaved) {
            return null
          }
          return (
            <>
              <li
                title={item.value}
                key={index}
                onClick={(e) => handleCheckboxChange(e, item)}
                onKeyDown={(e) => handleCheckboxChange(e, item)}
              >
                <label>
                  <input className="sdds-form-input" value={index} type="checkbox" checked={item.checked} onChange={(e) => handleCheckboxChange(e, item)}/>
                  {item.value} {item.type ? ` (${item.type})`: '' }
                </label>
              </li>

            </>
          )
        }
        )}
      </>
    )
  }

  return (
    <div>
      <div className="controls">
       <RBACWrapper oneOf requiredRoles={["Admin","GTS", "FQ"]}>
        <button
          className="sdds-btn sdds-btn-sm sdds-btn-secondary bttn"
          onClick={handleAddColumn}
          disabled={!MessageKeyList || MessageKeyList.length === 0}
          id="add-columns-to-table"
        >
          {t("TS_core:AddcolumnstoTable")}
        </button>
        </RBACWrapper>
        <button className="sdds-btn sdds-btn-sm sdds-btn-secondary bttn"
          onClick={handleResetDefaultData}
          disabled={!MessageKeyList || MessageKeyList.length === 0}
          id="reset-to-default"
        >
          {t("TS_core:ResetDefaultColumns")}
        </button>
      </div>
      <CustomModal
        id="historical-data-modal"
        title={t("TS_core:HistoricalDataVariablesModelTitle")}
        size="lg"
        handleClose={() => setAddColumnTableOpen(false)}
        show={addColumnTableOpen}
        actions={
          <>
            <button
              type="button"
              className="sdds-btn sdds-btn-sm sdds-btn-primary bttn"
              onClick={saveAddColumn}
            >
              {t("TS_core:SaveSelections")}
            </button>
            <button
              type="button"
              className="sdds-btn sdds-btn-sm sdds-btn-secondary bttn"
              onClick={clearAllUnsavedSelections}
            >
              {t("TS_core:ClearUnsavedSelections")}
            </button>
            <button
              type="button"
              className="sdds-btn sdds-btn-sm sdds-btn-secondary bttn"
              onClick={handleCancelButton}
            >
              {t("TS_core:Cancel")}
            </button>
          </>
        }
      >
        <div>
          <p>
            <strong>{t("TS_core:HistoricalDataColumnSelectionTitle")} </strong>
            <span className="sdds-chip sdds-chip__active">
              <span className="sdds-chip-text">
                {" "}
                {selectedList.length} {t("TS_core:Selected")}{" "}
              </span>
            </span>
            <input
              type="text"
              className="sdds-textfield-input"
              placeholder="filter variables by name"
              onChange={handleSearchItem}
            />
          </p>
          <div className="container">
            <ul className="list-items sdds-checkbox-item">
              {renderLists()}
            </ul>
          </div>
        </div>
      </CustomModal>


    </div>
  );
}

export default HistoricalDataVariablesDialog
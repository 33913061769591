import { TFunction } from "i18next";

const getColumnsConfig = (t: TFunction) => {
  return {
    column: {
      style: 'font-size:16px'
    },
    columns: [
      { columnid: 'timeRegistered', title: t("TS_core:TimeRegistered"), sortable: true },
      { columnid: 'gatewayReferenceId', title: t("TS_core:GatewayReferenceId"), sortable: true},
      { columnid: 'mobileId', title: t("TS_core:MobileId"), sortable: false },
      { columnid: 'model', title: t("TS_core:CommunicatorModel"), sortable: true,},
      //Due to version data not supporting history and the Telematic team not providing support for historical data for  the version, the CSTT team has decided to remove the version column from the table to avoid user confusion.
      // { columnid: 'version', title: t("TS_core:Version"), sortable: false },
      { columnid: 'link', title: '', sortable: false }
    ]
  };
}

export {
  getColumnsConfig
}
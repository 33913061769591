import React from "react";
import { JoyrideReusableTour } from "@cd/sdds-common-components-react";
import generateEquipmentTourStepsByRole from "./sc-tour-helper";

type SCStatusTourProps = {
  loggedInProfileInfo: {
    role: string;
  };
  start: boolean;
  isButtonLabelHide?: boolean;
  onTourClose?: (data: any) => void;
};

const SCStatusTour = ({ loggedInProfileInfo, start, isButtonLabelHide, onTourClose }:SCStatusTourProps ) => {
  return (
    <JoyrideReusableTour
      loggedInProfileInfo={loggedInProfileInfo}
      stepsByRole={(role): TourStep[] => generateEquipmentTourStepsByRole(role, 'serviceActivation') as TourStep[]}
      buttonLabel="Service Activation Result"
      tourKey="sa-details-tour"
      start={start}
      onTourClose={onTourClose}
      isButtonLabelHide={isButtonLabelHide}
    />
  );
};

export default SCStatusTour;

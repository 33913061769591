import React from "react";
import { useTranslation } from "react-i18next";
import ClockIconSvg from "../../../icons/clock";
import "./legendIndicator.scss";

const LegendIndicator = () => {
  const { t } = useTranslation();
  return <div className="latency-indicators">
    <div className="sdds-row" id="latency-icons">
      <div className="sdds-col-m-1">
        <div className="sdds-headline-07 sdds-u-mb1">{t("TS_core:LatencyIndicator")}</div>
      </div>
      <div className="sdds-col-m-1">
        <div className="sdds-row">
          <div className="li high">
            <ClockIconSvg /><span className='txt'> {`> 30 ${t("TS_core:Minutes")}`}</span>
          </div>
          <div className="li medium">
            <ClockIconSvg /><span className='txt'> {`< 30 ${t("TS_core:Minutes")}`}</span>
          </div>
        </div>
        <div className="sdds-row">
          <div className="li low">
            <ClockIconSvg /><span className='txt'> {`< 60 ${t("TS_core:Seconds")}`}</span>
          </div>
          <div className="li not-calculated">
            <ClockIconSvg /><span className='txt'>{t("TS_core:NotCalculated")}</span>
          </div>
        </div>
      </div>
    </div>
  </div>;
}

export default LegendIndicator;
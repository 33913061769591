import React from "react";
import i18next from "i18next";

type TourStep = {
  title: string | JSX.Element;
  target: string;
  content: JSX.Element;
  placement: string;
  disableBeacon: boolean;
};

const createStep = (title: string, target: string, description: string): TourStep => ({
  title: <h4 className="tourTitle">{title}</h4>,
  target,
  content: <p className="pageTour">{description}</p>,
  placement: "right-start",
  disableBeacon: true,
});

const tourStepsKeys: Record<"search" | "table" | "charts" | "maps", { key: string; label: string; description: string; roles: string[] }[]> = {
  search: [
    { key: "msg-types", label: "TS_temp:JR_CHSelectMsg", description: "TS_temp:JR_CHSelectMsgDescription", roles: ["Admin", "GTS", "FQ", "LoTH", "Assistance", "Basic"] },
    { key: "search-section", label: "TS_core:Search", description: "TS_temp:JR_CHSearchDescription", roles: ["Admin", "GTS", "FQ", "LoTH", "Assistance", "Basic"] },
    { key: "search-result", label: "TS_temp:JR_CHSearchResults", description: "TS_temp:JR_CHSearchResultDescription", roles: ["Admin", "GTS", "FQ", "LoTH", "Assistance", "Basic"] },
    { key: "result-number", label: "TS_temp:JR_CHMsgTypes", description: "TS_temp:JR_CHMsgTypesDescription", roles: ["Admin", "GTS", "FQ", "LoTH", "Assistance", "Basic"] },
  ],
  table: [    
    { key: "ch-table", label: "TS_core:Table", description: "TS_temp:JR_CHTableDescription", roles: ["Admin", "GTS", "FQ", "LoTH", "Assistance", "Basic"] },
    { key: "ch-table-data", label: "TS_temp:JR_CHTableView", description: "TS_temp:JR_CHTableViewDescription", roles: ["Admin", "GTS", "FQ", "LoTH", "Assistance", "Basic"] },
    { key: "sort-icon", label: "TS_temp:JR_CHSort", description: "TS_temp:JR_CHSortDescription", roles: ["Admin", "GTS", "FQ", "LoTH", "Assistance", "Basic"] },
    { key: "close-column", label: "TS_temp:JR_CHHideColumns", description: "TS_temp:JR_CHHideColumnsDescription", roles: ["Admin", "GTS", "FQ", "LoTH", "Assistance", "Basic"] },
    { key: "reset-to-default", label: "TS_core:ResetDefaultColumns", description: "TS_temp:JR_CHResetColumnsDescription", roles: ["Admin", "GTS", "FQ", "LoTH", "Assistance", "Basic"] },
    { key: "add-columns-to-table", label: "TS_core:AddcolumnstoTable", description: "TS_temp:JR_CHAddColumnsToTable", roles: ["Admin","GTS", "FQ"] },
    { key: "latency-icons", label: "TS_temp:JR_CHLatencyIndicators", description: "TS_temp:JR_CHLatencyIndicatorsDescription", roles: ["Admin", "GTS", "FQ", "LoTH", "Assistance", "Basic"] }
  ],
  charts: [
    { key: "ch-charts", label: "TS_core:Charts", description: "TS_temp:JR_CHChartsDescription", roles: ["Admin", "GTS", "FQ", "LoTH", "Assistance", "Basic"] },
    { key: "time-line-chart", label: "TS_temp:JR_CHTimeLineChart", description: "TS_temp:JR_CHTimeLineChartDescription", roles: ["Admin", "GTS", "FQ", "LoTH", "Assistance", "Basic"]},
  ],
  maps: [
    { key: "ch-map", label: "TS_core:Map", description: "TS_temp:JR_CHMapsDescription", roles: ["Admin", "GTS", "FQ", "LoTH", "Assistance"] },
    { key: "ch-map-settings", label: "TS_temp:JR_CHMapSettings", description: "TS_temp:JR_CHMapSettingsDescription", roles: ["Admin", "GTS", "FQ", "LoTH", "Assistance"] },
    { key: "ch-map-resize", label: "TS_temp:JR_CHMapResize", description: "TS_temp:JR_CHMapResizeDescription", roles: ["Admin", "GTS", "FQ", "LoTH", "Assistance"] },
    
  ],
};

const generateEquipmentTourStepsByRole = (userRole: string, type: keyof typeof tourStepsKeys) => {
  return (tourStepsKeys[type] || [])
    .filter((step) => step.roles.includes(userRole))
    .map(({ key, label, description }) =>
      createStep(i18next.t(label), `#${key}`, i18next.t(description))
    );
}

export default generateEquipmentTourStepsByRole;

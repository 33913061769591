import React from "react";
import { JoyrideReusableTour } from "@cd/sdds-common-components-react";
import generateEquipmentModuleTourStepsByRole from "./EquipmentModuleTourHelper";

type EquipmentModuleTourProps = {
  loggedInProfileInfo: {
    role: string;
  };
  start: boolean;
};

const EquipmentModuleTour = ({loggedInProfileInfo, start}: EquipmentModuleTourProps ) => {
  return (
    <JoyrideReusableTour
      loggedInProfileInfo={loggedInProfileInfo}
      stepsByRole={(role) => generateEquipmentModuleTourStepsByRole(role)}
      buttonLabel="Equipment"
      tourKey="equipment"
      start={start}
    />
  );
};

export default EquipmentModuleTour;

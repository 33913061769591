import React, { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import ServiceConfigurationExcelExportButton from "../ServiceConfigurationExcelExportButton";
import { cscDropDownDataList, getColumnsConfig, isDropDownsEditableCodeType, isSliderEditableCodeType } from "../../helpers/serviceConfigurationService";
import ExportIcon from "../../icons/ExportIcon";
import { DataTable, DataRow, DataCell, Toast, useToast, Spinner, Icons, JoyrideInSectionTour } from "@cd/sdds-common-components-react";
import { getCscValueDataList, updateOverideCscCodeData } from "../../redux/store/serviceConfiguration";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { UserContext } from '../../appContext';
import StepSlider from "./stepSlider";
import SCSupportedServicesTour from "../EquipmentTour/ServiceConfiguration/SCSupportedServicesTour";

interface ServiceConfigurationDataTablePropTypes {
  Data: Array<any>
  selectedVehicle: any
  onSortData?: (columnId: string, sortDirection: string) => void
  onSearch?: (searchString: string) => void
  onRefresh?: () => void
}

const ServiceConfigurationDataTable = ({ Data, onSortData, onSearch, selectedVehicle, onRefresh }: ServiceConfigurationDataTablePropTypes) => {
  const { t } = useTranslation()
  const columnConfig = getColumnsConfig(t)
  const cscEditableDataList = cscDropDownDataList(t)
  const dispatch = useAppDispatch();
  const { isCscValueUpdating, hasError, errorMessage, stepSliderConfig, isCscValueLoading, isLoading } = useAppSelector<CscCodeStateType>(state => state.cscEquipmentStore);
  const [isEditableCscCode, setEditableCscCode] = useState(false);
  const [selectedRow, setSelectedRow] = useState("");
  const [selectedCscValue, setselectedCscValue] = useState(0);
  const { toastQueue, addToast } = useToast();
  const { settings } = useContext(UserContext);
  const loggedInProfileInfo = useContext<ProfileInformationType>(UserContext);

  const handleOnClickChange = (cscCode: string) => {

    let cscCodeNumbericVal = Number(cscCode);
    //Skip API call for dropdown related cscCode
    if (cscCodeNumbericVal !== 218) {
      dispatch(getCscValueDataList(cscCodeNumbericVal));
    }
    setEditableCscCode(true)
    setSelectedRow(cscCode)
  }

  const handleOnClickCancel = () => {
    setEditableCscCode(false)
  }

  const handleDropDownChange = (value: any) => {
    setselectedCscValue(Number(value?.target.value))
  }

  const handleSliderChange = (value: any) => {
    setselectedCscValue(Number(value));
  }

  const handleOnClickSave = (cscCode: number) => {
    if (selectedCscValue >= 0) {
      dispatch(updateOverideCscCodeData(selectedVehicle.externalEquipmentReference, cscCode, selectedCscValue, settings?.language || 'en-GB'));
      setEditableCscCode(false)
      setselectedCscValue(0)
    } else {
      addToast({
        title: t("TS_core:UnExpectedErrorTitle"), text: t("TS_core:FailDueToIncorrectData"),
        type: "error"
      })
    }

  }

  useEffect(() => {
    if (isCscValueUpdating) {
      addToast({
        title: t(""), text: t("TS_core:ReportSettingsUpdateAndSend"),
        type: "success",
      })
    } else if (!isCscValueUpdating && (hasError && errorMessage !== '')) {
      addToast({
        title: t("TS_core:UnExpectedErrorTitle"), text: t("TS_core:FailToUpdateReportSettings"),
        type: "error"
      })
    }
  }, [hasError, errorMessage, isCscValueUpdating]);

  return (
    <>
      {isLoading && <div className='spin-container'><Spinner /></div>}
      <DataTable
        title={`${t("TS_temp:EnabledServices")}`}
        columns={columnConfig.columns}
        onSearchChange={onSearch}
        onSort={onSortData}
        actionBar={<>
        <div className="jy-sc-activation-table-section">
          <JoyrideInSectionTour
                  buttonLabel="start-sc-activation-services-tour-section"
                  tourComponent={SCSupportedServicesTour} 
                  loggedInProfileInfo={loggedInProfileInfo}
                  isButtonLabelHide={true}
                        />
        </div>
          {onRefresh && <button className="sdds-table__actionbar-btn" onClick={onRefresh} title={t("TS_core:Refresh")} disabled={isLoading}>
            <Icons.RefreshIcon />
          </button>}
          <ServiceConfigurationExcelExportButton data={Data} disabled={!Data || Data.length === 0}>
            <span>{t("TS_core:ExportToExcel")}</span><span className='sdds-btn-icon'><ExportIcon /></span>
          </ServiceConfigurationExcelExportButton>
        </>} >
        <>
          {Data && Data.map((row, index) => {
            return <DataRow key={`row-${index}`}>
              {columnConfig.columns.map((col: ColumnConfigType, colIndex: number) => {
                switch (col.columnid) {
                  case 'numValue':
                    return (
                      <DataCell key={`col-index-${colIndex}`} colSpan={3}>
                        {
                          //index comparison is added to support joyride flow in the page
                          ((selectedRow !== row.code) || !isEditableCscCode) && <span
                          {...(
                            index === 0 
                              ? { id: "enabled-services-value" } 
                              : row.code === 171 
                                ? { id: "enabled-services-limit" } 
                                : {}
                          )}
                        >
                          {row.numValue}
                        </span>
                        }
                        {
                          (isSliderEditableCodeType(row.code) || isDropDownsEditableCodeType(row.code)) && !isEditableCscCode &&
                          <>
                            <button {...(
                             row.code === 171 
                                ? { id: "enabled-services-change-limit" } 
                                : {}
                          )} className='sdds-btn sdds-btn-secondary sdds-btn-sm' onClick={() => handleOnClickChange(row.code)}>{t("TS_core:Edit")}</button></>

                        }
                        {
                          (isSliderEditableCodeType(row.code) || isDropDownsEditableCodeType(row.code)) && isEditableCscCode && (selectedRow === row.code) &&
                          <>
                            {isDropDownsEditableCodeType(row.code) && isEditableCscCode &&
                              <sdds-dropdown
                                placeholder="Select option" onClick={(value: any) => { handleDropDownChange(value) }} >
                                {cscEditableDataList.options.map((option: any) => (
                                  <sdds-dropdown-option value={option.key}>{option.value}</sdds-dropdown-option>))}
                              </sdds-dropdown>
                            }
                            {isCscValueLoading && <div className='loader'><Spinner /></div>}
                            {
                              isSliderEditableCodeType(row.code) && isEditableCscCode && stepSliderConfig && !isCscValueLoading &&
                              <>
                                <StepSlider
                                  id={`slider-${row.code}`}
                                  minValue={stepSliderConfig.minValue}
                                  maxValue={stepSliderConfig.maxValue}
                                  initialValue={row.numValue}
                                  ticks={row.code === 171 ? 0 : stepSliderConfig.ticks}
                                  step={stepSliderConfig.step}
                                  showTicks={row.code === 171}
                                  onSliderValueChange={handleSliderChange}
                                />
                              </>
                            }
                            <button className='sdds-btn sdds-btn-secondary sdds-btn-sm' onClick={handleOnClickCancel}>{t("TS_core:Cancel")}</button>
                            <button className='sdds-btn sdds-btn-primary sdds-btn-sm' onClick={() => handleOnClickSave(row.code)}>{t("TS_core:Save")}</button>
                          </>
                        }
                      </DataCell>
                    )
                  case 'action':
                  case '':
                    return <></>;

                  default:
                    return <DataCell key={`col-index-${colIndex}`}>
                      {/* //index & colIndex comparison is added to support ids for joyride flow in the page */}
                      <span
                        {...(
                          index === 0 && colIndex === 2 && row[col.columnid] === 'Function'
                            ? { id: "enabled-services-function" } 
                            : index === 1 && colIndex === 2 && row[col.columnid] === 'Feature'
                              ? { id: "enabled-services-feature" }
                              : {}
                        )}
                      > {row[col.columnid]}</span>
                    </DataCell>
                };
              })}
            </DataRow>;
          })}
        </>
      </DataTable>
      {<Toast toastQueue={toastQueue} />}
    </>
  )
}
export default ServiceConfigurationDataTable;
import { Header, NavLink, NoRoleDisclaimer, Toast, useToast } from "@cd/sdds-common-components-react"
import React, { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Outlet, useLocation } from "react-router-dom"
import { RBACWrapper } from "react-simple-rbac"
import { useAppSelector } from "../redux/store"
import { UserContext } from "../appContext"
import EquipmentModuleTour from "../components/EquipmentTour/EquipmentModuleTour"
import CHSearchTour from "../components/EquipmentTour/CommunicationHistory/CHSearchTour"
import CHTableTour from "../components/EquipmentTour/CommunicationHistory/CHTableTour"
import CHChartsTour from "../components/EquipmentTour/CommunicationHistory/CHChartsTour"
import SCStatusTour from "../components/EquipmentTour/ServiceConfiguration/SCStatusTour"
import SCSupportedServicesTour from "../components/EquipmentTour/ServiceConfiguration/SCSupportedServicesTour"
import CHMapsTour from "../components/EquipmentTour/CommunicationHistory/CHMapsTour"

const Index = () => {

  const { t } = useTranslation();
  const loggedInProfileInfo = useContext<ProfileInformationType>(UserContext);
  const { selectedCustomer, hasError, errorMessage } = useAppSelector<SearchState>(state => state.universalSearch)
  /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
  const [searchStatus, setSearchStatus] = useState<boolean>(false);
  /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
  const [searchResult, setSearchResult] = useState<string>('');
  const [scaniaSignedAndRead, setScaniaSignedAndRead] = useState<boolean>(true);
  const [activeTourKey, setActiveTourKey] = useState("");
  const currentApp = 'Equipment';

  const { toastQueue, addToast } = useToast()


  useEffect(() => {
    if (hasError && errorMessage !== '') {
      addToast({
        title: t("TS_core:UnExpectedErrorTitle"), text: t("TS_core:UnExpectedErrorMessage"),
        type: "error"
      })
    }
  }, [hasError, errorMessage]);

  const { pathname } = useLocation();
  const [tours, setTours] = useState<Array<{ key: string; element: JSX.Element ; disabled?: boolean;}>>([{ key: "equipment", element: <EquipmentModuleTour loggedInProfileInfo={loggedInProfileInfo} start={activeTourKey === "equipment"} /> }]);
  const { combinnedCommunicationData } = useAppSelector<CommunicationStateType>(state => state.communicationHistoryStore);
  
  /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
   const startTour = (tourKey: string) => {
     setActiveTourKey(tourKey);
 };

  useEffect(() => {
    if (selectedCustomer && (!selectedCustomer?.scaniaLegalDocumentationSigned || !selectedCustomer?.scaniaRead)) {
      setScaniaSignedAndRead(false);
    } else {
      if ((selectedCustomer === undefined || selectedCustomer === null) && (loggedInProfileInfo?.role === 'Admin' || loggedInProfileInfo?.role === 'GTS')) {
        setScaniaSignedAndRead(true);
      } else if ((selectedCustomer === undefined || selectedCustomer === null)) {
        setScaniaSignedAndRead(false);
      }
      else {
        setScaniaSignedAndRead(true);
      }
    }
  }, [selectedCustomer, selectedCustomer?.scaniaLegalDocumentationSigned, selectedCustomer?.scaniaRead, loggedInProfileInfo?.role,]);

  useEffect(() => {
    let newTours: Array<{ key: string; element: JSX.Element ; disabled?: boolean;}> = [
      { key: "equipment", element: <EquipmentModuleTour loggedInProfileInfo={loggedInProfileInfo} start={activeTourKey === "equipment"} /> }
    ];
    if (pathname.includes('/communication-history')) {
      const userRoles = loggedInProfileInfo?.role?.split(",");
      if (userRoles.some(role => ["Admin", "GTS", "LoTH", "FQ", "Basic", "Assistance"].includes(role))) {
        newTours.push( { key: "page-ch", element: <div className="jr-page-title tds-detail-04">{`-- ${t("TS_core:VehicleCommunicationHistory")} --`}</div>});
        newTours.push(
            { key: "search", element: <CHSearchTour loggedInProfileInfo={loggedInProfileInfo} start={activeTourKey === "search"} /> }
        );
        const isDataAvailable = combinnedCommunicationData.length > 0;
        newTours.push(
            { key: "table", element: <CHTableTour loggedInProfileInfo={loggedInProfileInfo} start={activeTourKey === "table"} />, disabled: !isDataAvailable },
            { key: "chart", element: <CHChartsTour loggedInProfileInfo={loggedInProfileInfo} start={activeTourKey === "chart"} />, disabled: !isDataAvailable },
          );
        if (userRoles.some(role => ["Admin", "GTS", "LoTH", "FQ", "Assistance"].includes(role))) {
          newTours.push(
            { key: "map", element: <CHMapsTour loggedInProfileInfo={loggedInProfileInfo} start={activeTourKey === "map"} />, disabled: !isDataAvailable  }
          );
        }
      }
    }
    if (pathname.includes('/service-configuration')) {
        newTours.push( { key: "page-sa", element: <div className="jr-page-title tds-detail-04">{`-- ${t("TS_core:ServiceConfiguration")} --`}</div>});
        newTours.push(
            { key: "serviceActivation", element: <SCStatusTour loggedInProfileInfo={loggedInProfileInfo} start={activeTourKey === "serviceActivation"} /> },
            { key: "supportedServices", element: <SCSupportedServicesTour loggedInProfileInfo={loggedInProfileInfo} start={activeTourKey === "supportedServices"} /> }
        );
    }
    setTours(newTours);
  }, [pathname, loggedInProfileInfo, activeTourKey, combinnedCommunicationData]);

  return (
    <>
    {(!loggedInProfileInfo.role || loggedInProfileInfo.role === null) ? (
          <>
          <NoRoleDisclaimer />
          </>
        ) : 
       (
        <>
        <RBACWrapper oneOf requiredRoles={["Admin", "GTS", "LoTH", "FQ", "Basic","BasicLimited","Assistance"]}>
      <Header
        profileInfo={loggedInProfileInfo}
        currentApp={currentApp}
        setSearchComplete={setSearchStatus}
        setSearchStatus={setSearchResult}
        equipmentRedirectPath={'communication'}
        newTours={tours}
      >          

        <RBACWrapper oneOf requiredRoles={["Admin", "GTS", "LoTH", "FQ", "Basic","BasicLimited"]}>
          <NavLink path="/communication" text={t("TS_core:Communication")} idd="communication_Tour_Id" />
        </RBACWrapper>
        <RBACWrapper oneOf requiredRoles={["Admin", "GTS", "FQ"]}>
          <NavLink path="/connected-services-deviations" text={t("TS_core:ConnectedServicesDeviations")} isScaniaRead={!scaniaSignedAndRead} uniqueId={"ConnectedServicesDeviationsSignedAndRead"} idd="connectedServicesDeviations_Tour_Id" />
        </RBACWrapper>
        <RBACWrapper oneOf requiredRoles={["Admin", "GTS", "FQ"]}>
          <NavLink path="/equipment-batch-search" text={t('TS_core:EquipmentBatchSearch')} idd={"EquipmentSatchSearch_Tout_id"} />
        </RBACWrapper>
          <NavLink path="/vehicle-rtc-history" text={t("TS_core:VehicleRtcHistory")} idd={"EquipmentRtcHistory_Tour_id"} />
      </Header>
      </RBACWrapper>
      <Outlet />
          </>
        ) 
      } 
      <Toast toastQueue={toastQueue} />
    </>

  )
}

export default Index
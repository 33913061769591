import React from "react";
import { JoyrideReusableTour } from "@cd/sdds-common-components-react";
import generateEquipmentTourStepsByRole from "./ch-tour-helper";

type CHSearchTourProps = {
  loggedInProfileInfo: {
    role: string;
  };
  start: boolean;
  isButtonLabelHide?: boolean;
  onTourClose?: (data: any) => void;
};

const CHSearchTour = ({ loggedInProfileInfo, start, onTourClose,isButtonLabelHide }: CHSearchTourProps) => {
  return (
    <JoyrideReusableTour
      loggedInProfileInfo={loggedInProfileInfo}
      stepsByRole={(role): TourStep[] => generateEquipmentTourStepsByRole(role, 'search') as TourStep[]}
      buttonLabel="Search for vehicle data"
      tourKey="search-tour"
      start={start}
      isButtonLabelHide={isButtonLabelHide}//To hide tour label from main joyride
      onTourClose={onTourClose}
    />
  );
};

export default CHSearchTour;

/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { RBACWrapper } from 'react-simple-rbac';
import { RootState, useAppDispatch, useAppSelector } from '../../redux/store';
import { EquipmentOverview, LoadAllSubscriptions, LoadLatestVehicleData, loadCommunicatorData, UniversalSearchActions, loadLatestDataFromCdc } from '@cd/sdds-common-components-react';
import { Outlet, useParams, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import './Communication.scss';
import { TooltipWithLockSvg } from '@cd/sdds-common-components-react/lib/esm/components/assets';
import { utcFormatDayFromString, utcFormatFromString } from '../../helpers/timeFormatter';
import { getOnlineStatus } from '@cd/sdds-common-components-react/lib/esm/store/EquipmentOverview/communicatorInfo';

type CommunicationProps = {
  loggedInProfileInfo: ProfileInformationType
}

const Communication = ({ loggedInProfileInfo }: CommunicationProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch()
  const { pathname } = useLocation();
  const { communicatorInfo, latestInfo, subscriptionInfo }: any = useAppSelector<RootState>(state => state)
  const { selectedVehicle, isEquipmentApisLoading, selectedCustomer, searchVehicleReference, saStatusLoading, saStatus, saStatusDate, csdStatsLoading, csdCount, csdDate, allCustomersForVehicle } = useAppSelector<SearchState>(state => state.universalSearch)
  const [eqSearchStatus, setEqSearchStatus] = useState<boolean>(false);
  const [activeItem, setActiveItem] = useState('');
  const [scaniaSignedAndRead, setScaniaSignedAndRead] = useState<boolean>(false);

  let { idParam } = useParams<any>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();
  let identifier = searchParams.get("identifier")

  const pagelinks = ['communication-history', 'service-configuration', 'rtc-log', 'mp-communication-log'];
  
  useEffect(() => {     
    if(idParam && idParam !== searchVehicleReference) {
      dispatch(UniversalSearchActions.fetchEquipment(idParam))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idParam])

  useEffect(() => {
    if (identifier) {
      dispatch(UniversalSearchActions.equipmentSearch(identifier))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [identifier])

  useEffect(() => {
    if(selectedVehicle){
      if (!idParam) {
        navigate('/communication/' + selectedVehicle.externalEquipmentReference, { replace: true })
      }
    }    
  }, [selectedVehicle])

  useEffect(() => {
    //   /* Preserve vehicle selection by setting external equipment ref value in state
    //      1. when vehicle selection is done in current module but user navigates away to a sub section that doesnt need vehicle selection (loses it from url) and then navigates back -selectedVehicle
    //      2. when vehicle selection was done in another module and is part of url when landing on this module -idParam
    //    */
    const pathSegments = pathname.split("/")
    const pageParam = pathSegments.pop();
    if (pageParam && pagelinks.indexOf(pageParam.toLowerCase()) > -1) {
      if (idParam === pageParam && selectedVehicle?.externalEquipmentReference) {
        navigate(`/communication/${selectedVehicle?.externalEquipmentReference}/${pageParam}`);
      } else {
        setActiveItem(pageParam);
      }
    }

  }, [pathname])

  useEffect(() => {
      if (selectedVehicle && selectedVehicle.externalEquipmentReference !== '00000000-0000-0000-0000-000000000000') {
        if (subscriptionInfo.equipmentVehicleReference !== selectedVehicle.externalEquipmentReference) {
          dispatch(LoadAllSubscriptions(selectedVehicle.externalEquipmentReference));
          dispatch(loadCommunicatorData(selectedVehicle.externalEquipmentReference));
          dispatch(getOnlineStatus(selectedVehicle.externalEquipmentReference));

        }        
      //load data for displaying configuration status & csd stats in equipment overview      
      dispatch(UniversalSearchActions.loadAggregateData(selectedVehicle.externalEquipmentReference));
      dispatch(UniversalSearchActions.loadCsdStats(selectedVehicle.externalEquipmentReference));
      }
  }, [selectedVehicle])

  useEffect(() => {    
    if(!subscriptionInfo.equipmentVehicleReference) return;
    
     if(subscriptionInfo.equipementTotalSubscribedCustomers?.length === 0){      
      dispatch(UniversalSearchActions.saveAllCustomersForVehicle([]));
      return;
    }  
   
    //iterate through the active subscription packages to get other customers for the vehicle besides the owner
    let customersWithSubscriptions = [...allCustomersForVehicle]
     subscriptionInfo.equipementTotalSubscribedCustomers.forEach( (p:any) => {      
      var aCustomer: SimpleCustomerType = {
        externalCustomerReference: p.externalCustomerReference,
        name: p.customerName,
      }
      if (p.hasActiveSubscriptions && !allCustomersForVehicle.find((element) => element.externalCustomerReference === aCustomer.externalCustomerReference) ) {        
        customersWithSubscriptions.push(aCustomer);
      }
    }); 
    //update customerList in store 
    dispatch(UniversalSearchActions.saveAllCustomersForVehicle(customersWithSubscriptions));    
  }, [subscriptionInfo.equipmentVehicleReference, subscriptionInfo.equipementTotalSubscribedCustomers.length]);

   useEffect(() => {    
    if(!selectedCustomer) return;
    
    /*  - selectedCustomer by default is always the vehicle owner
        - check and add vehicle owner to allCustomers list (sometimes the vehicle owner doesnt have an active subscription on the vehicle) */
    if(!allCustomersForVehicle.find((element) => element.externalCustomerReference === selectedCustomer.externalCustomerReference)){
      let allSavedCustomers = [...allCustomersForVehicle]
      allSavedCustomers.unshift(selectedCustomer); // always add vehicle owner as the first option in allCustomers list      
      dispatch(UniversalSearchActions.saveAllCustomersForVehicle(allSavedCustomers))//update in store customerList
    }      
  }, [selectedCustomer]); 

  useEffect(() => {
    if (communicatorInfo?.EquipmentInformation && selectedVehicle){
      reloadLatestData(selectedVehicle.externalEquipmentReference);
    }  
  }, [communicatorInfo?.EquipmentInformation])

  const handleClick = (item: any) => {
    if (selectedVehicle?.externalEquipmentReference) {
      idParam = selectedVehicle.externalEquipmentReference
    }
    if (!idParam) return;
    navigate(`/communication/${idParam}/${item}`);
  }
  const checkActive = (item: any, className: any) => {
    return activeItem === item ? className : "";
  }

  useEffect(() => {
    if (selectedCustomer && selectedCustomer?.scaniaLegalDocumentationSigned && selectedCustomer?.scaniaRead) {
      setScaniaSignedAndRead(false);
    } else {
      if ((selectedCustomer === undefined || selectedCustomer === null) && (loggedInProfileInfo?.role === 'Admin' || loggedInProfileInfo?.role === 'GTS')) {
        setScaniaSignedAndRead(false);
      }
      else {
        setScaniaSignedAndRead(true);
      }
    }
  }, [selectedCustomer, selectedCustomer?.scaniaLegalDocumentationSigned, selectedCustomer?.scaniaRead, loggedInProfileInfo?.role]);

  const reloadLatestData = (externalEquipmentReference: string) => {
    if (communicatorInfo?.EquipmentInformation?.rtcType?.toLowerCase() === 'c400') {
      dispatch(loadLatestDataFromCdc(externalEquipmentReference))
    } else {
      dispatch(LoadLatestVehicleData(externalEquipmentReference))
    }
  }
  return <>
    <div className='landing-page'>
      <EquipmentOverview searchStatus={eqSearchStatus} setSearchStatus={setEqSearchStatus}
        vehicle={selectedVehicle}
        customer={selectedCustomer}
        allCustomers={allCustomersForVehicle}
        subscriptionInfo={subscriptionInfo}
        reLoadAllSubscriptions={(externalEquipmentReference: string) => { dispatch(LoadAllSubscriptions(externalEquipmentReference)); }}
        communicatorInfo={communicatorInfo}
        reloadCommunicatorInfo={(externalEquipmentReference: string) => { dispatch(loadCommunicatorData(externalEquipmentReference)); }}
        latestInfo={latestInfo}
        isScaniaRead={selectedCustomer?.scaniaRead && selectedCustomer?.scaniaLegalDocumentationSigned}
        reLoadLatestVehicleData={reloadLatestData}
        isEquipmentApisLoading={isEquipmentApisLoading}
        equipmentRedirectPath="communication"
        loggedInProfileInfo={loggedInProfileInfo}
        saStatus={saStatus}
        saStatusDate={utcFormatFromString(saStatusDate)}
        isSaStatusLoading={saStatusLoading}
        csdCount={csdCount}
        csdDate={utcFormatDayFromString(csdDate)}
        isCsdStatsLoading={csdStatsLoading}
      />
      <div className="st-view-wrapper">
        {selectedVehicle &&
          <><sdds-inline-tabs-fullbleed>
             <RBACWrapper oneOf requiredRoles={["Admin", "GTS", "LoTH", "FQ", "Basic","Assistance"]}>
            <div role="button" className={`sdds-inline-tabs-fullbleed--tab ${checkActive('communication-history', "sdds-navigation-tabs--tab__active")}`} onClick={() => handleClick('communication-history')}>
            {scaniaSignedAndRead && <span className='tooltipLockIcon'><TooltipWithLockSvg tooltipText={t("TS_core:ScaniaReadForbidden")} uniqueId={'VehicleCommunicationHistoryInfoIcon'} /></span>} {t('TS_core:VehicleCommunicationHistory')}</div>
            </RBACWrapper>
            <div role="button" className={`sdds-inline-tabs-fullbleed--tab ${checkActive('service-configuration', "sdds-navigation-tabs--tab__active")}`} onClick={() => handleClick('service-configuration')}>{t("TS_core:ServiceConfiguration")}</div>
            <RBACWrapper oneOf requiredRoles={["Admin", "GTS", "FQ"]}>
              <div role="button" className={`sdds-inline-tabs-fullbleed--tab ${checkActive('rtc-log', "sdds-navigation-tabs--tab__active")}`} onClick={() => handleClick('rtc-log')}>
                {scaniaSignedAndRead && <span className='tooltipLockIcon'><TooltipWithLockSvg tooltipText={t("TS_core:ScaniaReadForbidden")} uniqueId={'RTCLogInfoIcon'} /></span>}
                {t("TS_core:RTCLog")}</div>
            </RBACWrapper>
            <RBACWrapper oneOf requiredRoles={["Admin", "GTS", "FQ"]}>
              <div role="button" className={`sdds-inline-tabs-fullbleed--tab ${checkActive('mp-communication-log', "sdds-navigation-tabs--tab__active")}`} onClick={() => handleClick('mp-communication-log')}>
                {scaniaSignedAndRead && <span className='tooltipLockIcon'><TooltipWithLockSvg tooltipText={t("TS_core:ScaniaReadForbidden")} uniqueId={'MPCommunicationLogInfoIcon'} /></span>}
                {t("TS_core:MPCommunicationLog")}</div>
            </RBACWrapper>
          </sdds-inline-tabs-fullbleed>
            <div className="panels">
              <Outlet />
            </div>
          </>
        }
      </div>
    </div>
  </>
};

export default Communication;
import React, { useState, useEffect, useContext } from "react"
import { RefreshIcon } from '@cd/sdds-common-components-react/lib/esm/components/assets';
import { useTranslation } from 'react-i18next';
import { utcFormatFromString } from "../../helpers/timeFormatter";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { activateEquipmentData } from '../../redux/store/serviceConfiguration';
import { Spinner, useToast, Toast, JoyrideInSectionTour } from '@cd/sdds-common-components-react';
import InfoIconWithLabel from "../InfoIconWithLabel";
import { useNavigate, useLocation } from "react-router-dom";
import ServiceConfigurationMachineryWrapper from './ServiceConfigurationMachineryResult/ServiceConfigurationMachineryWrapper';
import SCStatusTour from "../EquipmentTour/ServiceConfiguration/SCStatusTour";
import { UserContext } from "../../appContext";

interface ServiceConfigurationAggregatePropTypes {
  data: EquipmentAggregate
  aggregateDetails: EquipmentAggregateDetail
  refreshAggregateDetails: Function
  machineryResultList?: GroupedMachineryResult[]
}

const ServiceConfigurationAggregate = ({ data, aggregateDetails, refreshAggregateDetails, machineryResultList }: ServiceConfigurationAggregatePropTypes) => {

  const { t } = useTranslation();
  const [toggle, setToggle] = useState(true);
  const dispatch = useAppDispatch();
  const { isAggregateLoading, hasError, errorMessage, isActiveEquipmentLoading } = useAppSelector<CscCodeStateType>(state => state.cscEquipmentStore);
  const { toastQueue, addToast } = useToast()
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const loggedInProfileInfo = useContext<ProfileInformationType>(UserContext);

  const onRefreshIconClick = () => {
    setToggle(true);
    refreshAggregateDetails();
  }

  const onViewDetailsClick = () => {
    setToggle(!toggle)
    if (!toggle) {
      refreshAggregateDetails();
    }
  }

  /*show service activation details by default based on url*/
  useEffect(() => {
    if (pathname.includes('viewDetails')) {
      let pathSegments = pathname.split("/")
      pathSegments.pop();
      let navUrl = pathSegments.join("/")
      navigate(navUrl)
    }
  }, [pathname]);

  // useEffect(() => {
  //   if (pathname.includes('viewDetails') && !toggle) {
  //     let pathSegments = pathname.split("/")
  //     pathSegments.pop();
  //     let navUrl = pathSegments.join("/")
  //     navigate(navUrl)
  //   }

  //   if (!pathname.includes('viewDetails') && toggle) {
  //     navigate(`${pathname}/viewDetails`);
  //   }
  // }, [toggle]);


  useEffect(() => {
    if (isActiveEquipmentLoading) {
      addToast({
        title: t(""), text: t("TS_core:ResendCscActivationSuccess"),
        type: "success",
      })
    } else if (!isActiveEquipmentLoading && (hasError && errorMessage !== '')) {
      addToast({
        title: t("TS_core:UnExpectedErrorTitle"), text: t("TS_core:ResendCscActivationError"),
        type: "error"
      })
    }
  }, [hasError, errorMessage, isActiveEquipmentLoading]);

  const onResendconfigurationClick = () => {
    dispatch(activateEquipmentData(data.externalEquipmentReference));
  }

  // Enable the code for new designs
  const StatusIndicator: React.FC<{ status: string | null }> = ({ status }) => {
    const statusClass = status ? (
      status === 'Failed' ? 'failed' :
        status === 'Pending' ? 'pending' :
          status === 'Succeeded' ? 'success' : // Added check for "Success"
            'unknown' // Default to "unknown" if no match
    ) : 'unknown';

    return (
      <div className={`status-indicator ${statusClass} tds-headline-06`}>
        {status || t('TS_core:Unknown')}
      </div>
    );
  };

  return (
    <>
      <div className='sdds-row sar-title-section'><span className="tds-headline-05">{t("TS_core:ServiceActivationStatus")}</span>  <div className="tds-headline-05 jy-sc-status-section">
        <JoyrideInSectionTour
          buttonLabel="start-sc-status-tour-section"
          tourComponent={SCStatusTour}
          loggedInProfileInfo={loggedInProfileInfo}
          isButtonLabelHide={true}
        />
      </div><span className="time-info"><InfoIconWithLabel labelText={t("TS_core:AllTimeIsUTCTime")} /></span></div>
      <div className="sdds-row sdds-u-mt2 ">
        <div className="aggr-status-line">
          <div className="aggr-status" >
            <div className="tds-headline-06">{t("TS_core:ConfigurationStatus")} </div>
            <span id="sa-result"><StatusIndicator status={data.status} /> </span>
            <button id="sa-result-refresh" className='sdds-btn sdds-btn-ghost refresh-btn' title={`${t("TS_core:FetchCommunicatorOnlineStatus")}`} onClick={() => { onRefreshIconClick() }}><RefreshIcon /></button>
            <div className="tds-headline-06 tds-u-ml3">{t("TS_core:UpdatedAt")} </div>
            <span id="sa-result-time" className="tds-body-02 tds-u-ml1"> {utcFormatFromString(data.timeModify)}</span>
          </div>
          <div className="aggr-status-actions">
            <button className='sdds-btn sdds-btn-secondary' onClick={onViewDetailsClick}>{toggle ? t("TS_core:HideDetails") : t("TS_core:ViewDetails")}</button>
            <button className='sdds-btn sdds-btn-primary' onClick={() => { onResendconfigurationClick() }} id="sc_resend_configuration">{t("TS_core:ResendConfiguration")}</button>
          </div>
        </div>
        {toggle &&
          <>{isAggregateLoading && <div className='loader'><Spinner size="md" /></div>}
            {/* {!isAggregateLoading && <RBACWrapper oneOf requiredRoles={["LoTH", "Basic", "BasicLimited", "Assistance"]}><ServiceConfigurationAggregateDetail Data={aggregateDetails} /></RBACWrapper>} */}
            {!isAggregateLoading && <ServiceConfigurationMachineryWrapper Data={machineryResultList} />}
            <Toast toastQueue={toastQueue} /></>}
      </div>

    </>
  )
}

export default ServiceConfigurationAggregate;
import React, { RefObject, useEffect, useRef } from "react";
import { useTranslation } from 'react-i18next';
import dayjs from "dayjs";
import "@here/maps-api-for-javascript";

var ui: H.ui.UI;
var map: H.Map;
var markerGroup: H.map.Group;
var delayTypes: any[];
var selectedDelayType = {};
var messageFilter = {
  lowDelayFilter: true,
  mediumDelayFilter: true,
  highDelayFilter: true
};

let lowDelayIcon = '/assets/icons/green-dot.svg';
let mediumDelayIcon = '/assets/icons/orange-dot.svg';
let highDelayIcon = '/assets/icons/red-dot.svg';

var defaultPositionIcon: H.map.Icon;
var warningPositionIcon: H.map.Icon;
var dangerPositionIcon: H.map.Icon;

interface EventPropTypes {
  event: Function,
  target: any
};

interface CommunicationHistoryPropTypes {
  PositionList: PositionMsgType[]
}

const CommunicationHistoryMap = ({ PositionList }: CommunicationHistoryPropTypes) => {
  const { t } = useTranslation();
  const mapElement: RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);

  function init() {
    delayTypes = [
      {
        value: 1,
        text: 'position time to message time'
      },
      {
        value: 2,
        text: 'message time to time saved'
      }
    ];
    selectedDelayType = delayTypes[0];
    defaultPositionIcon = new window.H.map.Icon(lowDelayIcon, { size: { w: 20, h: 20 }, anchor: { x: 10, y: 10 } });
    warningPositionIcon = new window.H.map.Icon(mediumDelayIcon, { size: { w: 20, h: 20 }, anchor: { x: 10, y: 10 } });
    dangerPositionIcon = new window.H.map.Icon(highDelayIcon, { size: { w: 20, h: 20 }, anchor: { x: 10, y: 10 } });
  }

  // https://doogalbellend.blogspot.com/2018/08/here-maps-full-screen-control.html
  function hereMapsFullScreenControl(ui: H.ui.UI, myCustomControl: H.ui.Control) {
    const myCustomPanel = new H.ui.base.OverlayPanel();
    myCustomPanel.addClass("customPanel");

    // store original styles
    const mapDiv = ui.getMap().getElement() as HTMLElement;
    let divStyle: CSSStyleDeclaration = mapDiv.style;
    // if (mapDiv.runtimeStyle) {
    //   divStyle = mapDiv.runtimeStyle;
    // }
    const originalPos: string = divStyle.position;
    let originalWidth: string = divStyle.width;
    let originalHeight: string = divStyle.height;
    // ie8 hack
    if (originalWidth === "") {
      originalWidth = mapDiv.style.width;
    }
    if (originalHeight === "") {
      originalHeight = mapDiv.style.height;
    }
    const originalTop: string = divStyle.top;
    const originalLeft: string = divStyle.left;
    const originalZIndex: string = divStyle.zIndex;
    let bodyStyle: CSSStyleDeclaration = document.body.style;
    // if (document.body.runtimeStyle) {
    //   bodyStyle = document.body.runtimeStyle;
    // }
    const originalOverflow: string = bodyStyle.overflow;

    const myCustomButton = new H.ui.base.PushButton({
      label: '<img id="ch-map-resize" src="https://cdn.digitaldesign.scania.com/icons/website_hosted/redirect.svg">',
      data: '',
      onStateChange: (evt) => {
        // OK, button state changed... if it's currently down
        if (myCustomButton.getState() === H.ui.base.Button.State.DOWN) {
          // go full screen
          mapDiv.style.position = "absolute";
          mapDiv.style.width = "100%";
          mapDiv.style.height = "calc(100vh - 64px)";
          mapDiv.style.top = "64px";
          mapDiv.style.left = "0";
          mapDiv.style.zIndex = "100";
          document.body.style.overflow = "hidden";
        } else {
          // exit full screen
          if (originalPos === "") {
            mapDiv.style.position = "relative";
          } else {
            mapDiv.style.position = originalPos;
          }
          mapDiv.style.width = originalWidth;
          mapDiv.style.height = originalHeight;
          mapDiv.style.top = originalTop;
          mapDiv.style.left = originalLeft;
          mapDiv.style.zIndex = originalZIndex;
          document.body.style.overflow = originalOverflow;
        }
        ui.getMap().getViewPort().resize();
      },
    });

    myCustomPanel.addChild(myCustomButton as any);
    myCustomControl.addChild(myCustomButton);
    myCustomButton.addClass('H_center');
    myCustomPanel.setState(H.ui.base.OverlayPanel.State.OPEN);
  }

  function getFilterVisibilityForMarker(marker: H.map.Marker, messageDelayType: any) {
    var message = marker.getData();
    var messageTimestamp = new Date(message.messageTimestamp);
    var gpsTimestamp = new Date(message.gpsTimestamp);
    var timeSaved = new Date(message.timeSaved);

    var delay = (messageDelayType.value === 1) ? (messageTimestamp.getTime() - gpsTimestamp.getTime()) / 1000 : (timeSaved.getTime() - messageTimestamp.getTime()) / 1000;
    if ((messageFilter.lowDelayFilter && delay < 60) ||
      (messageFilter.mediumDelayFilter && (delay >= 60 && delay <= 1800)) ||
      (messageFilter.highDelayFilter && delay > 1800)) {
      return true;
    } else {
      return false;
    }
  }

  function updateMarkerDelayIcon() {
    markerGroup.forEach(function (marker) {
      var markerTmp = marker as H.map.Marker;
      var positionMessageType: PositionMsgType = marker.getData();
      markerTmp.setIcon(getMarkerIconForDelayType(positionMessageType, selectedDelayType));
      marker.setVisibility(getFilterVisibilityForMarker(markerTmp, selectedDelayType));
    });
  }

  function applyMessageFilter() {
    var messageDelayType = selectedDelayType;
    markerGroup.forEach(function (marker) {
      marker.setVisibility(getFilterVisibilityForMarker(marker as H.map.Marker, messageDelayType));
    });
  }

  function setupCustomizedMapControl(customizedMapControl: H.ui.Control) {
    var controlPanel = new window.H.ui.base.OverlayPanel();
    var radioGroup = new window.H.ui.base.RadioGroup();
    radioGroup.setTitle(t("TS_core:CalculateDelayFor"));
    radioGroup.addClass('H_rdo_custom_text_labels');

    var onboardButton = new window.H.ui.base.PushButton({
      label: '<strong>' + t("TS_core:Onboard") + '</strong><span>' + t("TS_core:PositionTimeToMessageTime") + '</span>',
      data: 'Test data',
      onStateChange: function () {
        if (onboardButton.getState() === 'down') {
          selectedDelayType = delayTypes[0];
          updateMarkerDelayIcon();
        }
      }
    });
    onboardButton.setState(H.ui.base.Button.State.DOWN);
    radioGroup.addButton(onboardButton);

    var offboardButton = new window.H.ui.base.PushButton({
      label: t("TS_core:Offboard") + '<span>' + t("TS_core:MessageTimeToTimeSaved") + ' </span>',
      data: 'offboard data',
      onStateChange: function () {
        if (offboardButton.getState() === 'down') {
          selectedDelayType = delayTypes[1];
          updateMarkerDelayIcon();
        }
      }
    });
    radioGroup.addButton(offboardButton);
    controlPanel.addChild(radioGroup);

    var menuText = new window.H.ui.base.Element('div', 'H_menu_title');
    menuText.renderInternal = function (element) {
      element.innerHTML = t("TS_core:DisplayDelayLevels");
    };
    controlPanel.addChild(menuText);

    var tempLowButton = new window.H.ui.base.PushButton({
      label: '<span><img src="' + lowDelayIcon + '" width=20 height=20 />' + t("TS_core:Low") + '</span>',
      data: 'temp low data',
      onStateChange: function () {
        if (tempLowButton.getState() === 'down') {
          messageFilter.lowDelayFilter = true;
        } else {
          messageFilter.lowDelayFilter = false;
        }
        applyMessageFilter();
      }
    });
    tempLowButton.addClass('H_btn_no_shadow');
    tempLowButton.setState(H.ui.base.Button.State.DOWN);
    controlPanel.addChild(tempLowButton);

    var tempMediumButton = new window.H.ui.base.PushButton({
      label: '<span><img src="' + mediumDelayIcon + '" width=20 height=20 />' + t("TS_core:Medium") + '</span>',
      data: '',
      onStateChange: function () {
        if (tempMediumButton.getState() === 'down') {
          messageFilter.mediumDelayFilter = true;
        } else {
          messageFilter.mediumDelayFilter = false;
        }
        applyMessageFilter();
      }
    });
    tempMediumButton.addClass('H_btn_no_shadow');
    tempMediumButton.setState(H.ui.base.Button.State.DOWN);
    controlPanel.addChild(tempMediumButton);

    var tempHighButton = new window.H.ui.base.PushButton({
      label: '<span><img src="' + highDelayIcon + '" width=20 height=20 />' + t("TS_core:High") + '</span>',
      data: '',
      onStateChange: function () {
        if (tempHighButton.getState() === 'down') {
          messageFilter.highDelayFilter = true;
        } else {
          messageFilter.highDelayFilter = false;
        }
        applyMessageFilter();
      }
    });
    tempHighButton.addClass('H_btn_no_shadow');
    tempHighButton.setState(H.ui.base.Button.State.DOWN);
    controlPanel.addChild(tempHighButton);

    var mapControlButton = new window.H.ui.base.PushButton({
      label: '<img id="ch-map-settings" src="https://cdn.digitaldesign.scania.com/icons/website_hosted/settings.svg">',
      data: '',
      onStateChange: function () {
        if (mapControlButton.getState() === 'down') {
          controlPanel.pointToControl(customizedMapControl);
          controlPanel.setState(H.ui.base.OverlayPanel.State.OPEN);
        } else {
          controlPanel.setState(H.ui.base.OverlayPanel.State.CLOSED);
        }
      }
    });
    customizedMapControl.addChild(mapControlButton);
    customizedMapControl.addChild(controlPanel);
    customizedMapControl.setAlignment(H.ui.LayoutAlignment.TOP_RIGHT);
    ui.addControl('customizedMapControl', customizedMapControl);
  }

  function updateMap(reCenterMap: boolean) {
    markerGroup.removeAll();
    if (PositionList && PositionList.length > 0) {
      drawDataPointsToMap(map, PositionList);
      const boundingBox = markerGroup.getBoundingBox();
      if (reCenterMap && boundingBox !== null) {
        map.getViewModel().setLookAtData({
          bounds: boundingBox
        });
      }
    }
  }

  function drawDataPointsToMap(map: H.Map, points: PositionMsgType[]) {
    for (var i = 0; i < points.length; i++) {
      if(points[i].gpsLatitude && points[0].gpsLongitude){
        var marker = new window.H.map.Marker(
          {
            lat: points[i].gpsLatitude,
            lng: points[i].gpsLongitude
          }
        );
        marker.setIcon(getMarkerIconForDelayType(points[i], selectedDelayType));
        marker.setData(points[i]);
        markerGroup.addObject(marker);
      }
    }
    markerGroup.addEventListener('tap', onMarkerClick);
    map.addObject(markerGroup);
  }

  function onMarkerClick(e: any) {
    var bubbles = ui.getBubbles();
    if (bubbles.length > 0) {
      bubbles[0].close()
      bubbles.pop();
    }

    var position = e.target.getGeometry();
    var data = e.target.getData();    
    var tooltipContent = '<strong>' + t("TS_core:PositionTime") + ': </strong><br>' + dayjs.utc(data.gpsTimestamp).format('DD/MM/YYYY HH:mm:ss') + '<br>' +
        '<strong>' + t("TS_core:MessageTime") + ': </strong><br>' + dayjs.utc(data.messageTimestamp).format('DD/MM/YYYY HH:mm:ss') + '<br>' +
        '<strong>' + t("TS_core:MessageSaveTime") + ': </strong><br>' + dayjs.utc(data.timeSaved).format('DD/MM/YYYY HH:mm:ss') + '<br>' +
        '<strong> HDOP </strong><br>' + (data.hdop ? data.hdop : t("TS_core:Unknown")) + '';

    var tooltip = new window.H.ui.InfoBubble(position, {
      content: tooltipContent
    });
    tooltip.addClass('here-map-tooltip');
    ui.addBubble(tooltip);
  }


  function getMarkerIconForDelayType(data: PositionMsgType, delayType: any) {
    var gpsTimestamp = new Date(data.gpsTimestamp);
    var messageTimestamp = new Date(data.messageTimestamp);
    var saveTime = data.timeSaved ? data.timeSaved : '';
    var timeSaved = new Date(saveTime);

    var delay = 0;
    if (delayType.value === 1) {
      delay = (messageTimestamp.getTime() - gpsTimestamp.getTime()) / 1000;
    } else {
      delay = (timeSaved.getTime() - messageTimestamp.getTime()) / 1000;
    }

    return (delay < 60) ? defaultPositionIcon : (delay < 1800) ? warningPositionIcon : dangerPositionIcon;
  }

  useEffect(() => {
    if (!mapElement.current)
      return;
    init();

    const platform = new H.service.Platform({ 'apikey': 'nSODGwb6hqN-hae3-EQcOqPzQbKpwuEZQ3cYxbxgg14' });
    let defaultLayers = platform.createDefaultLayers();

    const vector = Object.getOwnPropertyDescriptor(defaultLayers, 'vector');
    const normal = Object.getOwnPropertyDescriptor(vector?.value, 'normal')
    const mapLayer = Object.getOwnPropertyDescriptor(normal?.value, 'map')

    map = new H.Map(
      mapElement.current,
      mapLayer?.value,
      {
        zoom: 15,
        center: { lng: 17.64566, lat: 59.159161 }, // Default zoom in södertälje
        pixelRatio: window.devicePixelRatio || 1
      }
    );

    new H.mapevents.Behavior(new window.H.mapevents.MapEvents(map));
    ui = window.H.ui.UI.createDefault(map, defaultLayers);
    markerGroup = new window.H.map.Group();
    map.addEventListener('pointermove', function (event: EventPropTypes) {
      if (event.target instanceof window.H.map.Marker) {
        map.getViewPort().element.style.cursor = 'pointer';
      } else {
        map.getViewPort().element.style.cursor = 'auto';
      }
    }, false);

    const myCustomControl = new H.ui.Control();
    setupCustomizedMapControl(myCustomControl);
    hereMapsFullScreenControl(ui, myCustomControl);
    // Select the node that will be observed for mutations
    const targetNode = document.querySelector(".map-container");
    const canvas = document.getElementsByTagName("canvas")[0];


    // Options for the observer (which mutations to observe)
    const config = { attributes: true, subtree: true };

    // Callback function to execute when mutations are observed
    const callback = (mutationList: Array<any>, observer: any) => {
      mutationList.forEach((mutation: MutationRecord) => {
        const target = mutation.target as Element
        if (mutation.type === "attributes"
          && ['style', 'width'].includes(`${mutation.attributeName}`)
          && ["map-container", "map-control"].includes(target.className)) {
          setTimeout(() => {
            ui.getMap().getViewPort().resize();
            updateMap(true);
          }, 100);
        } else {
          if (canvas.clientWidth !== canvas.parentElement?.clientHeight) {
            ui.getMap().getViewPort().resize()
          }
        }
      });
    }

    if (targetNode) {
      // Create an observer instance linked to the callback function
      const observer = new MutationObserver(callback);

      // Start observing the target node for configured mutations
      observer.observe(targetNode, config);


      return () => { // Later, you can stop observing
        observer.disconnect();
      }
    }
  }, []);

  useEffect(() => {
    if (PositionList.length > 0)
      updateMap(true);
  }, [PositionList]);

  return (
    <>
      <div className="map-control" ref={mapElement} />
    </>
  )
};

export default CommunicationHistoryMap;



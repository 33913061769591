import React from "react";
import { JoyrideReusableTour } from "@cd/sdds-common-components-react";
import generateEquipmentTourStepsByRole from "./ch-tour-helper";

type CHTableTourProps = {
  loggedInProfileInfo: {
    role: string;
  };
  start: boolean;
  isButtonLabelHide?: boolean;  
  onTourClose?: (data: any) => void;
};

const CHTableTour = ({ loggedInProfileInfo, start, isButtonLabelHide, onTourClose }:CHTableTourProps ) => {
  return (
    <JoyrideReusableTour
      loggedInProfileInfo={loggedInProfileInfo}
      stepsByRole={(role): TourStep[] => generateEquipmentTourStepsByRole(role, 'table') as TourStep[]}
      buttonLabel="Data table"
      tourKey="table-tour"
      start={start}
      onTourClose={onTourClose}
      isButtonLabelHide={isButtonLabelHide}
    />
  );
};

export default CHTableTour;

import React, { useContext, useState } from "react"
import './SearchAndFilter.scss';
import { useTranslation } from "react-i18next";
import { DateSearch, JoyrideInSectionTour } from '@cd/sdds-common-components-react';
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import { clone } from 'lodash';
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { setSearchCheckboxInputsIntoStore } from "../../../redux/store/communicationHistory";
import { UserContext } from "../../../appContext";
import CHSearchTour from "../../EquipmentTour/CommunicationHistory/CHSearchTour";
import InfoIconWithLabel from "../../InfoIconWithLabel";

dayjs.extend(utc)
interface SearchAndFilterPropTyps {
  searchInputPrnt: SearchInput | undefined,
  onSearch: (fromDate: string, toDate: string, messageTypes: string[]) => void
}

type SearchInput = {
  fromDate: string,
  toDate: string,
  messageTypes: string[]
}

const SearchAndFilter = ({ searchInputPrnt, onSearch }: SearchAndFilterPropTyps) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch();
  const { searchMessageChecks } = useAppSelector<CommunicationStateType>(state => state.communicationHistoryStore);
  const [messageTypes, setMessageTypes] = useState<string[]>(['CurrentStatus', 'Position', 'FunctionIndication']);
  const loggedInProfileInfo = useContext<ProfileInformationType>(UserContext);

  const onSearchClick = (fromDate:string, toDate:string) => {
    onSearch(fromDate, toDate, messageTypes);
  }
  const onMessageTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let mTypes: string[] = clone(messageTypes)
    let messageChecks: IHashMap<boolean> = clone(searchMessageChecks);

    const checkBoxValue = e.target.value;
    if (e.target.checked) {
      if (!mTypes.includes(checkBoxValue)) {
        mTypes = mTypes.concat([checkBoxValue]);
        messageChecks[checkBoxValue] = true;
      }
    } else {
      if (mTypes.includes(checkBoxValue)) {
        mTypes.splice(mTypes.indexOf(checkBoxValue), 1);
        messageChecks[checkBoxValue] = false;
      }
    }
    setMessageTypes(mTypes);
    dispatch(setSearchCheckboxInputsIntoStore(messageChecks));
  }

  // useEffect(() => {
  //   if (typeof searchInputPrnt !== 'undefined') {
  //     setFromDate(moment.utc(searchInputPrnt.fromDate).toDate());
  //     setToDate(moment.utc(searchInputPrnt.toDate).toDate())
  //     setMessageTypes(searchInputPrnt.messageTypes)
  //     setFromTime(moment.utc(searchInputPrnt.fromDate).toDate());
  //     setToTime(moment.utc(searchInputPrnt.toDate).toDate())
  //   }
  // }, [searchInputPrnt])

  // useEffect(() => {
  //   let duration = moment.duration(moment(toDate).diff(fromDate, "hours", true));
  //   duration.asHours() < 0 ? setInvalidSearchPeriod(true) : setInvalidSearchPeriod(false)
  // }, [fromDate, toDate]);

  // useEffect(() => {
  //   if (invalidSearchPeriod)
  //     setErrorMsg(t("TS_core:InvalidSearchPeriod"));
  // }, [invalidSearchPeriod]);

  return <div  className="search-wrapper sdds-u-pl2 sdds-u-pt2 sdds-u-pb2">

    <div className='sdds-col-xs-12 sdds-col-sm-12'>
      <div className="sdds-row space-between">
        <div className='sdds-headline-05 jr-ch-search'>{t("TS_core:SearchCommunicationHistory")} 
        <div className='jy-ch-chart-in-section-tour'><JoyrideInSectionTour buttonLabel="start-ch-search-tour-section" tourComponent={CHSearchTour} loggedInProfileInfo={loggedInProfileInfo} isButtonLabelHide={true} /></div>
       </div>
        <div className="flex time-info">
        <InfoIconWithLabel labelText={t('TS_core:AllTimeIsUTCTime')} />
        </div>

      </div>

      <div className='flex-display'>
        <div id="msg-types" className="flex-display col">
          <div className='sdds-headline-06 sdds-u-mt2'>{t("TS_core:MessageTypes")}</div>
          <div className="sdds-u-mt2 flex-display">
            <div className="sdds-checkbox-item">
              <input className="sdds-form-input" checked={searchMessageChecks["CurrentStatus"]} type="checkbox" name="cb-example" id="current-status" onChange={onMessageTypeChange} value="CurrentStatus" />
              <label className="sdds-form-label" htmlFor="current-status">
                <div className="marker current-status"></div>{t("TS_core:CurrentStatus")}
              </label>
            </div>
            <div className="sdds-checkbox-item">
              <input className="sdds-form-input" checked={searchMessageChecks["Position"]} type="checkbox" name="cb-example" id="position" onChange={onMessageTypeChange} value="Position" />
              <label className="sdds-form-label" htmlFor="position">
                <div className="marker position"></div>{t("TS_core:Position")}
              </label>
            </div>
            <div className="sdds-checkbox-item">
              <input className="sdds-form-input" checked={searchMessageChecks["FunctionIndication"]} type="checkbox" name="cb-example" id="function-indication" onChange={onMessageTypeChange} value="FunctionIndication" />
              <label className="sdds-form-label" htmlFor="function-indication">
                <div className="marker function-indication"></div>{t("TS_core:FunctionIndication")}
              </label>
            </div>
          </div>
        </div>
        <div id="search-section">
        <DateSearch qsOptions={['Last24Hours', 'PreviousDay', 'NextDay', 'Last3Days', 'Last7Days', 'Last30Days']}
          onSearchClick={onSearchClick}
          defaultStartDate={searchInputPrnt?.fromDate}
          defaultEndDate={searchInputPrnt?.toDate}
          />
          </div>

      </div>
    </div>
  </div>;
}

export default SearchAndFilter
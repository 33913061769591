import React from "react";
import i18next from "i18next";

type TourStep = {
  title: string | JSX.Element;
  target: string;
  content: JSX.Element;
  placement: "top" | "bottom" | "left" | "right" | "right-start" | "left-start" | undefined;
  disableBeacon: boolean;
};

const createStep = (title: string, target: string, description: string): TourStep => ({
  title: <h4 className="tourTitle">{title}</h4>,
  target,
  content: <p className="pageTour">{description}</p>,
  placement: "right-start",
  disableBeacon: true,
});

const tourStepsKeys: Record<"serviceActivation" | "supportedServices", { key: string; label: string; description: string; roles: string[] }[]> = {
  serviceActivation: [
    { key: "sa-result", label: "TS_temp:JR_SAResult", description: "TS_temp:JR_SAResultDescription", roles: ["Admin", "GTS", "LoTH", "FQ", "Basic","BasicLimited","Assistance"] },
    { key: "sa-result-refresh", label: "TS_temp:JR_SAResultRefresh", description: "TS_temp:JR_SAResultRefreshDescription", roles: ["Admin", "GTS", "LoTH", "FQ", "Basic","BasicLimited","Assistance"] }, 
    { key: "sa-result-time", label: "TS_temp:JR_SAResultTime", description: "TS_temp:JR_SAResultTimeDescription", roles: ["Admin", "GTS", "LoTH", "FQ", "Basic","BasicLimited","Assistance"] },
    { key: "sc_resend_configuration", label: "TS_core:ResendConfiguration", description: "TS_temp:JR_SARResendConfigurationDescription", roles: ["Admin", "GTS", "LoTH", "FQ", "Basic","BasicLimited","Assistance"] },
    { key: "sc_latest_tab", label: "TS_temp:JR_SARLatest", description: "TS_temp:JR_SARLatestDescription", roles: ["Admin", "GTS", "LoTH", "FQ", "Basic","BasicLimited","Assistance"] },
    { key: "sc_history_tab", label: "TS_temp:JR_SARHistory", description: "TS_temp:JR_SARHistoryDescription", roles: ["Admin", "GTS", "LoTH", "FQ", "Basic","BasicLimited","Assistance"] },
    { key: "sa-reason", label: "TS_temp:JR_SAReason", description: "TS_temp:JR_SAReasonDescription", roles: ["Admin", "GTS", "LoTH", "FQ", "Basic","BasicLimited","Assistance"] },
    { key: "csc-code-column", label: "TS_temp:JR_CSCCodes", description: "TS_temp:JR_CSCCodesDescription", roles: ["Admin", "GTS", "LoTH", "FQ", "Basic","BasicLimited","Assistance"] },
    { key: "csc-status", label: "TS_temp:JR_CSCStatus", description: "TS_temp:JR_CSCStatusDescription", roles: ["Admin", "GTS", "LoTH", "FQ", "Basic","BasicLimited","Assistance"] },
    { key: "sa-support-tooltip", label: "TS_temp:JR_SASupportTooltip", description: "TS_temp:JR_SASupportTooltipDescription", roles: ["Admin", "GTS", "LoTH", "FQ", "Basic","BasicLimited","Assistance"] },
    { key: "sar-limitations", label: "TS_temp:JR_SARLimitations", description: "TS_temp:JR_SARLimitation1Description", roles: ["Admin", "GTS", "LoTH", "FQ", "Basic","BasicLimited","Assistance"] },
    { key: "sa-result", label: "TS_temp:JR_SARLimitations", description: "TS_temp:JR_SARLimitation2Description", roles: ["Admin", "GTS", "LoTH", "FQ", "Basic","BasicLimited","Assistance"] },
    { key: "sar-limitations", label: "", description: "TS_temp:JR_SARActionDescription", roles: ["Admin", "GTS", "LoTH", "FQ", "Basic","BasicLimited","Assistance"] },   
  ],
  supportedServices: [
    { key: "enabled-services", label: "TS_temp:JR_EnabledServices", description: "TS_temp:JR_EnabledServicesDescription", roles: ["Admin", "GTS", "LoTH", "FQ", "Basic","BasicLimited","Assistance"] },
    { key: "enabled-services", label: "TS_temp:JR_EnabledServicesFunction", description: "TS_temp:JR_EnabledServicesFunctionDescription", roles: ["Admin", "GTS", "LoTH", "FQ", "Basic","BasicLimited","Assistance"] },
    { key: "enabled-services", label: "TS_temp:JR_EnabledServicesFunctionSupport", description: "TS_temp:JR_EnabledServicesFunctionSupportDescription", roles: ["Admin", "GTS", "LoTH", "FQ", "Basic","BasicLimited","Assistance"] },   
    { key: "enabled-services", label: "TS_temp:JR_EnabledServicesFeature", description: "TS_temp:JR_EnabledServicesFeatureDescription", roles: ["Admin", "GTS", "LoTH", "FQ", "Basic","BasicLimited","Assistance"] },
    { key: "enabled-services", label: "TS_temp:JR_EnabledServicesValue", description: "TS_temp:JR_EnabledServicesValueDescription", roles: ["Admin", "GTS", "LoTH", "FQ", "Basic","BasicLimited","Assistance"] },
    { key: "enabled-services", label: "TS_temp:JR_EnabledServicesLimit", description: "TS_temp:JR_EnabledServicesLimitDescription", roles: ["Admin", "GTS", "LoTH", "FQ", "Basic","BasicLimited","Assistance"] },
    { key: "enabled-services", label: "TS_temp:JR_EnabledServicesChangeLimit", description: "TS_temp:JR_EnabledServicesChangeLimitDescription", roles: ["Admin", "GTS", "LoTH", "FQ", "Basic","BasicLimited","Assistance"] },
  ],
};

const generateEquipmentTourStepsByRole = (userRole: string, type: keyof typeof tourStepsKeys) => {
  return (tourStepsKeys[type] || [])
    .filter((step) => step.roles.includes(userRole))
    .map(({ key, label, description }) =>
      createStep(i18next.t(label), `#${key}`, i18next.t(description))
    );
};

export default generateEquipmentTourStepsByRole;

import React from "react";
import generateEquipmentTourStepsByRole from "./ch-tour-helper";
import { JoyrideReusableTour } from "@cd/sdds-common-components-react";

type CHTableTourProps = {
  loggedInProfileInfo: {
    role: string;
  };
  start: boolean;
  isButtonLabelHide?: boolean;
  onTourClose?: (data: any) => void;
};

const CHMapsTour = ({ loggedInProfileInfo, start, isButtonLabelHide, onTourClose }:CHTableTourProps ) => {
  return (
    <JoyrideReusableTour
      loggedInProfileInfo={loggedInProfileInfo}
      stepsByRole={(role): TourStep[] => generateEquipmentTourStepsByRole(role, 'maps') as TourStep[]}
      buttonLabel="Position data in maps"
      tourKey="maps-tour"
      start={start}
      isButtonLabelHide={isButtonLabelHide}
      onTourClose={onTourClose}
    />
  );
};

export default CHMapsTour;

import { t } from 'i18next';

const generateEquipmentModuleTourStepsByRole = (userRole: string): any => {

    const equipmentOverviewLabel: string = t("TS_core:EquipmentOverview");
    const communicationLabel: string = t("TS_core:Communication");
    const csdLabel: string = t("TS_core:ConnectedServicesDeviations");
    const equipmentSearchBatch: string =t("TS_core:EquipmentBatchSearch");
    const feedbackLabel: string =t("TS_core:SendFeedback");
    const equipmentOverviewDesciption: string = t("TS_core:W_EqipmentOverview");
    const communicationDesciption: string = t("TS_core:W_Communication");
    const csdDesciption: string = t("TS_core:W_CSD");
    const equipmentSearchBatchDesciption: string = t("TS_core:W_EquiBatchSearch");
    const feedbackDescription: string = t("TS_core:W_Feedback");
    const vehicleRtcHistory: string =t("TS_core:VehicleRtcHistory");
    const vehicleRtcHistoryDescription: string =t("TS_temp:JR_VehicleRtcHistoryDescription");

    if(userRole === "Admin" || userRole === "GTS")
    {
        return([
            {
                title:<h4 className="tourTitle">{equipmentOverviewLabel}</h4>,
                target:"#equipOverview",
                content:<p className ='pageTour'>{equipmentOverviewDesciption}</p>,
                placement: "right-start" as const,
                disableBeacon: true
            },
            {
                title:<h4 className="tourTitle">{communicationLabel}</h4>,
                target:"#communication_Tour_Id",
                content:<p className ='pageTour'>{communicationDesciption}</p>,
                disableBeacon: true
            },
            {
                title:<h4 className="tourTitle">{csdLabel}</h4>,
                target:"#connectedServicesDeviations_Tour_Id",
                content:<p className ='pageTour'>{csdDesciption}</p>,
                disableBeacon: true
            },
            {
                title:<h4 className="tourTitle">{equipmentSearchBatch}</h4>,
                target:"#EquipmentSatchSearch_Tout_id",
                content:<p className ='pageTour'>{equipmentSearchBatchDesciption}</p>,
                disableBeacon: true
            },
            {
                title:<h4 className="tourTitle">{vehicleRtcHistory}</h4>,
                target:"#EquipmentRtcHistory_Tour_id",
                content:<p className ='pageTour'>{vehicleRtcHistoryDescription}</p>,
                disableBeacon: true
            },
            {
                title:<h4 className="tourTitle">{feedbackLabel}</h4>,
                target:'#surveySliderBtn',
                content:<p className ='pageTour'>{feedbackDescription}</p>
            }
        ])
    }
    else if(userRole === "FQ"){
        return([
            {
                title:<h4 className="tourTitle">{equipmentOverviewLabel}</h4>,
                target:"#equipOverview",
                content:<p className ='pageTour'>{equipmentOverviewDesciption}</p>,
                placement: "right-start" as const,
                disableBeacon: true
            },
            {
                title:<h4 className="tourTitle">{communicationLabel}</h4>,
                target:"#communication_Tour_Id",
                content:<p className ='pageTour'>{communicationDesciption}</p>,
                disableBeacon: true
            },
            {
                title:<h4 className="tourTitle">{equipmentSearchBatch}</h4>,
                target:"#EquipmentSatchSearch_Tout_id",
                content:<p className ='pageTour'>{equipmentSearchBatchDesciption}</p>,
                disableBeacon: true
            },
            {
                title:<h4 className="tourTitle">{feedbackLabel}</h4>,
                target:'#surveySliderBtn',
                content:<p className ='pageTour'>{feedbackDescription}</p>
            }
        ])
    }
    else if(userRole === "LoTH" || userRole === "Basic"){
        return([
            {
                title:<h4 className="tourTitle">{equipmentOverviewLabel}</h4>,
                target:"#equipOverview",
                content:<p className ='pageTour'>{equipmentOverviewDesciption}</p>,
                placement: "right-start" as const,
                disableBeacon: true
            },
            {
                title:<h4 className="tourTitle">{communicationLabel}</h4>,
                target:"#communication_Tour_Id",
                content:<p className ='pageTour'>{communicationDesciption}</p>,
                disableBeacon: true
            },
            {
                title:<h4 className="tourTitle">{vehicleRtcHistory}</h4>,
                target:"#EquipmentRtcHistory_Tour_id",
                content:<p className ='pageTour'>{vehicleRtcHistoryDescription}</p>,
                disableBeacon: true
            },
            {
                title:<h4 className="tourTitle">{feedbackLabel}</h4>,
                target:'#surveySliderBtn',
                content:<p className ='pageTour'>{feedbackDescription}</p>
            }
        ])
    }
    else{ //BasicLimited & Assistance
        return([
            {
                title:<h4 className="tourTitle">{equipmentOverviewLabel}</h4>,
                target:"#equipOverview",
                content:<p className ='pageTour'>{equipmentOverviewDesciption}</p>,
                placement: "right-start" as const,
                disableBeacon: true
            },
            {
                title:<h4 className="tourTitle">{vehicleRtcHistory}</h4>,
                target:"#EquipmentRtcHistory_Tour_id",
                content:<p className ='pageTour'>{vehicleRtcHistoryDescription}</p>,
                disableBeacon: true
            },
            {
                title:<h4 className="tourTitle">{feedbackLabel}</h4>,
                target:'#surveySliderBtn',
                content:<p className ='pageTour'>{feedbackDescription}</p>
            }
        ])
    }
}

export default generateEquipmentModuleTourStepsByRole;
